import { mapGetters } from 'vuex';
import { formDescriptionClickElement } from '../helpers/userNavigation';

module.exports = {
	computed: {
		...mapGetters({
			userData: 'Common/userData',
			prevPointAction: 'others/prevPointAction',
			langId: 'lang/id',
		})
	},
	methods: {
		sendEventToSlonik(payload = {}) {
			this._slonik({
				event_id: payload.event_id || 9999,
				screentype: this.isDesktop ? 1 : (this.isMobile ? 2 : 3),
				lang_id: this.langId,
				previous_url: payload.click_action ? undefined : encodeURIComponent(document.referrer),
				owner_id: this.userData.userId,
				form_type: payload.form_type,
				current_point_action: payload.current_point_action ? (payload.current_point_action.target ? formDescriptionClickElement(click.target) : payload.current_point_action) : undefined,
				previous_point_action: this.prevPointAction,
				click_action: payload.click_action,
				billing_order_id: payload.billing_order_id,
				balance_personal: payload.balance_personal,
				balance_promotional: payload.balance_promotional,
				balance_publications: payload.balance_publications,
				search_text: payload.search_text,
				error_add_advert_form_application: payload.error,
				is_discount: payload.discount
			});
		}
	},
};
