import {mapGetters} from 'vuex';

export default {
  i18n: require('./i18n').default,
  data: function () {
	return {
	  bold: undefined
	};
  },
  computed: {
	...mapGetters({
	  account_: 'cabinet/balance/account',
	}),
  },
  mounted() {
	this.bold = this._result(this.account_, 'value.data');
  }
};
