import { mapGetters } from 'vuex';

export default {
  name: 'OrderPackage',
  computed: {
    ...mapGetters({
      segment: 'cabinet/segment',
    }),
  },
  i18n: {
    messages: {
      ru: {
        'Зустрічайте нові вигоди!': 'Встречайте новые выгоды!',
        'Заощаджуйте до': 'Экономьте до',
        'на публікаціях': 'на публикациях',
        'і піднятті в ТОП': 'и поднятии в ТОП',
        'Дізнатись більше': 'Узнать больше',
      },
      uk: {
        'Зустрічайте нові вигоди!': 'Зустрічайте нові вигоди!',
        'Заощаджуйте до': 'Заощаджуйте до',
        'на публікаціях': 'на публікаціях',
        'і піднятті в ТОП': 'і піднятті в ТОП',
        'Дізнатись більше': 'Дізнатись більше',
      },
    },
  },
};
