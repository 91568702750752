import Timer from '../../../../FinalPage/common/Timer/index.vue';
import CabinetSlidesComponent from '../CabinetSlidesComponent/index.vue';
export default {
  props: {
    endDate: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      date: new Date(this.endDate),
    };
  },
  name: 'CabinetSlidesBlockId163',
  components: {
    Timer,
    CabinetSlidesComponent
  },
};
