import {mapGetters} from "vuex";

export default {
	data    : function () {
		return {
		}
	},
	name: "ForBusiness",
	init({store}) {
		let {'cabinet/blocks/blocks': blocks} = store.getters;
		return blocks[this.name] ? store.dispatch("cabinet/forBusiness/forBusiness") : '';
	},
	computed: {
		...mapGetters({
			forBusiness: "cabinet/forBusiness/forBusiness"
		})
	},
  i18n: {
    messages: {
      uk: {
        'для Вашего бизнеса':'для Вашого бізнесу',
      },
      ru: {
        'для Вашего бизнеса':'для Вашего бизнеса',

      }
    }
  }
}