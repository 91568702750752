import translate from './i18n';

export default {
  data() {
    return {};
  },
  i18n: {
    ...translate,
  },
};
