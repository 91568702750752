export default {
	messages: {
		ru: {
			'рекомендует': 'рекомендует',
			'Ваши выгоды на публикациях': 'Ваши выгоды на публикациях',
			'увеличьте ее': 'увеличьте ее',
			'пополнив Личный счет от': 'пополнив Личный счет от',
			'Отримуйте вигоди при поповненні Особистого рахунку': 'Получайте выгоды при пополнении Личного счета',
			'Персональные рекоммендации для вас от': 'Персональные рекоммендации для вас от',
			'Публікуйте і піднімайте в ТОП з вигодою до 50%!': 'Публикуйте и поднимайте в ТОП с выгодой до 50%!',
			'Поповніть Особистий рахунок, щоб отримати додаткову вигоду': 'Пополните Личный счет, чтобы получить дополнительную выгоду',
			'Отримати вигоду': 'Получить выгоду',
			'Получайте выгоду при пополнении счета': 'Получайте выгоду при пополнении счета',
			'Поповніть рахунок та отримайте вигоду': 'Пополните счет и получите выгоду',
		},
		uk: {
			'рекомендует': 'рекомендує',
			'Ваши выгоды на публикациях': 'Ваші вигоди на публікаціях',
			'увеличьте ее': 'збільшіть її',
			'пополнив Личный счет от': 'поповнивши Особистий рахунок від',
			'Отримуйте вигоди при поповненні Особистого рахунку': 'Отримуйте вигоди при поповненні Особистого рахунку',
			'Персональные рекоммендации для вас от': 'Персональні рекомендації для вас від',
			'Публікуйте і піднімайте в ТОП з вигодою до 50%!': 'Публікуйте і піднімайте в ТОП з вигодою до 50%!',
			'Поповніть Особистий рахунок, щоб отримати додаткову вигоду': 'Поповніть Особистий рахунок, щоб отримати додаткову вигоду',
			'Отримати вигоду': 'Отримати вигоду',
			'Получайте выгоду при пополнении счета': 'Отримуйте вигоду при поповненні рахунку',
			'Поповніть рахунок та отримайте вигоду': 'Поповніть рахунок та отримайте вигоду',
		}
	}
};
