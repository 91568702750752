export default {
  messages: {
    uk: {
      'Є ідея, як покращити AUTO.RIA, DIM.RIA та RIA.com?': 'Є ідея, як покращити AUTO.RIA, DIM.RIA та RIA.com?',
      'Поділіться нею тут!': 'Поділіться нею тут!',
    },
    ru: {
      'Є ідея, як покращити AUTO.RIA, DIM.RIA та RIA.com?': 'Есть идея, как улучшить AUTO.RIA, DIM.RIA и RIA.com?',
      'Поділіться нею тут!': 'Поделитесь ею здесь!',
    },
  },
};
