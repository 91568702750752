import { mapGetters } from 'vuex';

export default {
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			counters: 'cabinet/leftBar/counters',
			segment: 'cabinet/segment',
		}),
		_activeCount() {
			if (this.counters && (this.counters.MyAdvertisementsPublished || this.counters.UsedAutoAdvertisementsPublished)) {
				return this._result(this, 'counters.MyAdvertisementsPublished.count') || this._result(this, 'counters.UsedAutoAdvertisementsPublished.count');
			}
			return 0;
		},
		_unpayedCount() {
			if (this.counters && (this.counters.MyAdvertisementsUnpayed || this.counters.UsedAutoAdvertisementsUnpayed)) {
				return this._result(this, 'counters.MyAdvertisementsUnpayed.count') || this._result(this, 'counters.UsedAutoAdvertisementsUnpayed.count');
			}
			return 0;
		},
		_draftCount() {
			if (this.counters && (this.counters.MyAdvertisementsDraft || this.counters.UsedAutoAdvertisementsDraft)) {
				return this._result(this, 'counters.MyAdvertisementsDraft.count') || this._result(this, 'counters.UsedAutoAdvertisementsDraft.count');
			}
			return 0;
		},
		_archiveCount() {
			if (this.counters && (this.counters.MyAdvertisementsArchive || this.counters.UsedAutoAdvertisementsArchive)) {
				return this._result(this, 'counters.MyAdvertisementsArchive.count') || this._result(this, 'counters.UsedAutoAdvertisementsArchive.count');
			}
			return 0;
		}
	},
	i18n: {
		messages: {
			uk: {
				'Мои объявления': 'Мої оголошення',
				Активные: 'Активні',
				Черновики: 'Чернетки',
				Архив: 'Архів',
				'Ожидают оплаты': 'Очікують оплати'
			},
			ru: {
				'Мои объявления': 'Мои объявления',
				Активные: 'Активные',
				Черновики: 'Черновики',
				Архив: 'Архив',
				'Ожидают оплаты': 'Ожидают оплаты'
			}
		}
	}
};
