import {mapGetters} from 'vuex';

export default {
	name: 'ManageCalls',
	i18n: require('./i18n').default,
	data: function () {
		return {
		};
	},
	computed: {
		...mapGetters({
			manageCalls: 'cabinet/content/manageCalls'
		})
	}
}
