export default {
	messages: {
		uk: {
            'ТОП Максимум — акция выгодной продажи!': 'ТОП Максимум — акція вигідного продажу!',
            'Поднимайте авто в ТОП на 3+ дня и получайте': 'Піднімайте авто в ТОП на 3+ дні та отримуйте',
            'до 35% уровней в подарок.': 'до 35% рівнів у подарунок.',
            'Только для авто': 'Тільки для авто',
            'с указанным VIN-кодом.': 'з вказаним VIN-кодом.',
            'Быстрая продажа': 'Швидкий продаж',
            'Молниеносная продажа': 'Блискавичний продаж',
            'Мгновенная продажа': 'Миттєвий продаж',
            'уровня': 'рівня',
            'До конца акции': 'До кінця акції',
            'дней': 'днів',
            'Поднять в ТОП': 'Підняти в ТОП',
		},
		ru: {
            'ТОП Максимум — акция выгодной продажи!': 'ТОП Максимум — акция выгодной продажи!',
            'Поднимайте авто в ТОП на 3+ дня и получайте': 'Поднимайте авто в ТОП на 3+ дня и получайте',
            'до 35% уровней в подарок.': 'до 35% уровней в подарок.',
            'Только для авто': 'Только для авто',
            'с указанным VIN-кодом.': 'с указанным VIN-кодом.',
            'Быстрая продажа': 'Быстрая продажа',
            'Молниеносная продажа': 'Молниеносная продажа',
            'Мгновенная продажа': 'Мгновенная продажа',
            'уровня': 'уровня',
            'До конца акции': 'До конца акции',
            'дней': 'дней',
            'Поднять в ТОП': 'Поднять в ТОП',
		}
	}
};
