import {mapGetters, mapMutations} from 'vuex';
import _laxios from '../../../../store/_laxios';
import scrollToTop from '../../../../helpers/cabinetScroll';

module.exports = {
	data: function () {
		return {};
	},
	name: 'Notifications',
	init({store, context}) {
		let {'cabinet/blocks/blocks': blocks} = store.getters;
		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}
		return blocks[this.name] ? Promise.all([
			store.dispatch('cabinet/notifications/notifications', cookies),
			store.dispatch('cabinet/notifications/counters', cookies)
		]) : '';
	},
	created() {
		this.notifications.messages && Object.keys(this.notifications.messages).length && this.notifications.messages.map((item) => {
			switch (Number(item.notification_type)) {
				case 1:
					item.notification_class = '_error';
					break;
				case 2:
					item.notification_class = '_warning';
					break;
				default:
					item.notification_class = '_info';
					break;
			}
		});
	},
	methods: {
		/**
     * відмічає, що сповіщення переглянуте
     * змінює лічильники
     * @param notification
     */
		showNotification(notification) {
			this.reloadMainPage();
			if (!notification.is_viewed) {
				switch (notification.notification_type) {
					case 1:
						this.decrementEmergencyCnt();
						this.incrementEmergencyViewedCnt();
						notification.is_viewed = true;
						break;
					case 2:
						this.decrementImportantCnt();
						this.incrementImportantViewedCnt();
						notification.is_viewed = true;
						break;
					case 3:
						this.decrementInformingCnt();
						this.incrementInformingViewedCnt();
						notification.is_viewed = true;
						break;
				}

				let data = {
					values: {
						is_viewed: true
					},
					filter: {
						nc_notification_id: [notification.nc_notification_id]
					}
				};

				// відправляємо запит на notification про перегляд
				_laxios.notifications_PATCH
					.request({data}).catch((e) => {
					console.error('notifications_PATCH', e.message);
				});
			}

		},
		scrollToTop,
		/**
     * Мутації для лічильників
     */
		...mapMutations({
			decrementEmergencyCnt: 'cabinet/leftBar/decrementEmergencyCnt',
			decrementImportantCnt: 'cabinet/leftBar/decrementImportantCnt',
			decrementInformingCnt: 'cabinet/leftBar/decrementInformingCnt',
			incrementEmergencyViewedCnt: 'cabinet/notifications/incrementEmergencyViewedCnt',
			incrementInformingViewedCnt: 'cabinet/notifications/incrementInformingViewedCnt',
			incrementImportantViewedCnt: 'cabinet/notifications/incrementImportantViewedCnt',
			reloadMainPage: 'cabinet/notifications/reloadMainPage'
		})
	},
	computed: {
		...mapGetters({
			notifications: 'cabinet/notifications/notifications',
			notificationCounters: 'cabinet/leftBar/notificationCounters',
			langId: 'lang/id'
		})
	},
	i18n: {
		messages: {
			uk: {
				'Уведомления': 'Сповіщення',
				'Будьте в курсе всех интересующих вас событий': 'Будьте в курсі всіх подій, що вас цікавлять',
				'Перейти к уведомлениям': 'Перейти до сповіщень',
				'Посмотреть все уведомления': 'Переглянути усі сповіщення'
			},
			ru: {
				'Уведомления': 'Уведомления',
				'Будьте в курсе всех интересующих вас событий': 'Будьте в курсе всех интересующих вас событий',
				'Перейти к уведомлениям': 'Перейти к уведомлениям',
				'Посмотреть все уведомления': 'Посмотреть все уведомления',
			}
		}
	}
};
