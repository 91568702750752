import { mapGetters } from 'vuex';
import { SEGMENT } from '../../../../../enums/segments';

const SEGMENTS = [
  SEGMENT.C2C,
  SEGMENT.SMB1_2,
  SEGMENT.SMB3_PLUS,
  SEGMENT.B2C_AUTO_DEALER,
  SEGMENT.B2C_AUTO_SALON,
  SEGMENT.B2C_AUTO_SALE,
  SEGMENT.B2C_AUTO_ORDER_CALLS,
  SEGMENT.B2C_AUTO_DEALER_COMP_EXP,
  SEGMENT.B2C_AUTO_DISASSEMBLE,
];

export default {
  name: 'PromoAPP',
  components: {
    store: () => import('./Store/index.vue'),
  },
  data() {
    return {
      selectedBuyer: false,
      selectedSeller: false,
      isSeller: true,
      messageBuyer: [0, 1, 2, 3, 4, 5, 6],
      messageSeller: [0, 1, 2],
    };
  },
  computed: {
    ...mapGetters({
      segment: 'cabinet/segment',
    }),
  },
  mounted() {
    if (SEGMENTS.includes(this.segment)) {
      this.selectedSeller = this.messageSeller[Math.floor(Math.random() * this.messageSeller.length)];
    } else {
      this.selectedBuyer = this.messageBuyer[Math.floor(Math.random() * this.messageBuyer.length)];
    }
  },
  i18n: {
    messages: {
      uk: {
        'Усі пропозиції автосалонів': 'Усі пропозиції автосалонів',
        'у вашій долоні': 'у вашій долоні',
        'Обирайте нові авто з додатку': 'Обирайте нові авто з додатку',
        'з додатком': 'з додатком',
        'Дзвоніть продавцям безкоштовно': 'Дзвоніть продавцям безкоштовно',
        'в додатку': 'в додатку',
        'Дізнавайтесь першим': 'Дізнавайтесь першим',
        'про цікаві вам авто': 'про цікаві вам авто',
        'Підписки в додатку': 'Підписки в додатку',
        'Сповістимо про зміну ціни': 'Сповістимо про зміну ціни',
        збережених: 'збережених',
        'Обране в додатку': 'Обране в додатку',
        'Дивіться відеоповідомлення продавців у додатку': 'Дивіться відеоповідомлення продавців у додатку',
        'Оглядайте авто, залишаючись вдома': 'Оглядайте авто, залишаючись вдома',
        'Відеодзвінки в додатку': 'Відеодзвінки в додатку',
        'Збережені авто доступні навіть без мережі': 'Збережені авто доступні навіть без мережі',
        'Все для швидкого продажу': 'Все для швидкого продажу',
        'вашого авто': 'вашого авто',
        'Публікуйте, перевіряйте': 'Публікуйте, перевіряйте',
        'рекламуйте в пошуку': 'рекламуйте в пошуку',
        'піднімайте в ТОП': 'піднімайте в ТОП',
        'Проводьте покази в кількох': 'Проводьте покази в кількох',
        'містах одразу': 'містах одразу',
        'Ваша історія про авто продаватиме за вас': 'Ваша історія про авто продаватиме за вас',
        'Відеоповідомлення в додатку': 'Відеоповідомлення в додатку',
      },
      ru: {
        'Усі пропозиції автосалонів': 'Все предложения автосалонов',
        'у вашій долоні': 'у вас в смартфоне',
        'Обирайте нові авто з додатку': 'Выбирайте новые авто в приложении',
        'з додатком': 'в приложении',
        'Дзвоніть продавцям безкоштовно': 'Звоните продавцам бесплатно',
        'в додатку': 'в приложении',
        'Дізнавайтесь першим': 'Узнавайте первыми',
        'про цікаві вам авто': 'об интересных вам авто',
        'Підписки в додатку': 'Подписки в приложении',
        'Сповістимо про зміну ціни': 'Сообщим о снижении цены',
        збережених: 'сохраненных',
        'Обране в додатку': 'Избранное в приложении',
        'Дивіться відеоповідомлення продавців у додатку': 'Смотрите видеосообщения продавцов в приложении',
        'Оглядайте авто, залишаючись вдома': 'Осматривайте авто, оставаясь дома',
        'Відеодзвінки в додатку': 'Видеозвонки в приложении',
        'Збережені авто доступні навіть без мережі': 'Сохраненные авто доступны даже без сети',
        'Все для швидкого продажу': 'Все для быстрой продажи',
        'вашого авто': 'вашего авто',
        'Публікуйте, перевіряйте': 'Публикуйте, проверяйте',
        'рекламуйте в пошуку': 'рекламируйте в поиске',
        'піднімайте в ТОП': 'поднимайте в ТОП',
        'Проводьте покази в кількох': 'Проводите показы сразу в',
        'містах одразу': 'нескольких городах',
        'Ваша історія про авто продаватиме за вас': 'Ваша история про авто будет продавать сама',
        'Відеоповідомлення в додатку': 'Видеосообщения в приложении',

      },
    },
  },
};
