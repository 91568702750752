export default {
	messages: {
		ru: {
			'Избранное': 'Избранное',
			'Быстрый доступ к сохраненным авто': 'Быстрый доступ к сохраненным авто',
			'Цена на интересующие вас предложения снижена': 'Цена на интересующие вас предложения снижена',
			'Объявление удалено и не принимает участие в поиске!': 'Объявление удалено и не принимает участие в поиске!',
			'новый': 'новый',
			'договорная цена': 'договорная цена',
			'тыс. км': 'тыс. км',
			'Объявление добавлено': 'Объявление добавлено',
			'Перейти к поиску автомобилей': 'Перейти к поиску автомобилей',
			'Смотреть': 'Смотреть',
			'все': 'все',
			'предложений': 'предложение | предложение | предложения | предложение'
		},
		uk: {
			'Избранное': 'Обране',
			'Быстрый доступ к сохраненным авто': 'Швидкий доступ до збережених авто',
			'Цена на интересующие вас предложения снижена': 'Ціна на пропозиції, що вас цікавлять знижена',
			'Объявление удалено и не принимает участие в поиске!': 'Оголошення видалено і не бере участь в пошуку!',
			'новый': 'новий',
			'договорная цена': 'договірна ціна',
			'тыс. км': 'тис. км',
			'Объявление добавлено': 'Оголошення додано',
			'Перейти к поиску автомобилей': 'Перейти до пошуку автомобілів',
			'Смотреть': 'Дивитись',
			'все': 'усі',
			'предложений': 'пропозицію | пропозиція | пропозиції | пропозицію'
		}
	}
};
