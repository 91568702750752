export default {
	messages: {
		ru: {
			'Действующие коммерческие услуги': 'Действующие коммерческие услуги',
			'Пакет': 'Пакет',
			'Продлить пакет': 'Продлить пакет',
			'Пополнить счет': 'Пополнить счет',
			'Активен': 'Активен',
			'день': 'дней | день | дня | дней',
			'до': 'до',
			'Рекомендует': 'Рекомендует',
			'рекомендует': 'рекомендует',
			'Купить пакет': 'Купить пакет',
			'Cкидка': 'Cкидка',
			'при размещении на': 'при размещении на',
			'месяцев': 'месяцев',
			'месяц': 'месяц | месяца | месяцев',
			'Продлить': 'Продлить',
		},
		uk: {
			'Действующие коммерческие услуги': 'Діючі комерційні послуги',
			'Пакет': 'Пакет',
			'Продлить пакет': 'Продовжити пакет',
			'Пополнить счет': 'Поповнити рахунок',
			'Активен': 'Aктивний',
			'день': 'днів | день | дні | днів',
			'до': 'до',
			'Рекомендует': 'Рекомендує',
			'рекомендует': 'рекомендує',
			'Купить пакет': 'Придбати пакет',
			'Cкидка': 'Знижка',
			'при размещении на': 'при розміщенні на',
			'месяцев': 'місяців',
			'месяц': 'місяць | місяця | місяців',
			'Продлить': 'Продовжити',
		}
	}
};
