import Timer from '../../../../FinalPage/common/Timer/index.vue';

export default {
  props: {
    background: {
      type: String,
      required: false,
      default: "",
    },
    mobileBackground: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: false,
      default: "#003b59",
    },
    desktopImageUrl: {
      type: String,
      required: false,
      default: "",
    },
    mobileImageUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
};
