export default {
	messages: {
		ru: {
			'Вся история ваших оплат на': 'Вся история ваших оплат на',
			'Пополните Личный счет и пользуйтесь им для оплаты сервисов': 'Пополните Личный счет и пользуйтесь им для оплаты сервисов',
			'Перейти к оплатам': 'Перейти к оплатам',
			'Пополнить счет': 'Пополнить счет'
		},
		uk: {
			'Вся история ваших оплат на': 'Вся історія ваших оплат на',
			'Пополните Личный счет и пользуйтесь им для оплаты сервисов': 'Поповніть Особистий рахунок і користуйтеся ним для оплати сервісів',
			'Перейти к оплатам': 'Перейти до оплат',
			'Пополнить счет': 'Поповнити рахунок'
		}
	}
};
