export default {
	messages: {
		ru: {
			'Неоплаченные счета': 'Неоплаченные счета',
			'Перейти к оплате': 'Перейти к оплате',
			'Смотреть все неоплаченные счета': 'Смотреть все неоплаченные счета'
		},
		uk: {
			'Неоплаченные счета': 'Неоплачені рахунки',
			'Перейти к оплате': 'Перейти до оплати',
			'Смотреть все неоплаченные счета': 'Дивитися всі неоплачені рахунки'
		}
	}
};
