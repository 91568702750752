export default {
	messages: {
		ru: {
			'Подписка': 'Подписка',
			'Создавайте подписки и получайте свежие объявления, автоновости и онлайн-журнал': 'Создавайте подписки и получайте свежие объявления, автоновости и онлайн-журнал',
			'Настроить подписку': 'Настроить подписку'
		},
		uk: {
			'Подписка': 'Підписка',
			'Создавайте подписки и получайте свежие объявления, автоновости и онлайн-журнал': 'Створюйте підписки і отримуйте свіжі оголошення, автоновини і онлайн-журнал',
			'Настроить подписку': 'Hалаштувати підписку'
		}
	}
};
