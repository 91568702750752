import {mapGetters} from 'vuex';

export default {
	i18n: require('./i18n').default,
	name: 'InvestmentsAndProfit',
	computed: {
		...mapGetters({
			investmentBlocks: 'cabinet/content/investmentsAndProfit',
		})
	},
};
