import {mapGetters} from 'vuex';

const {set, get} = require('../../../../helpers/cookie');
const PERIOD = 604800; // (60 * 60 * 24 * 30)

export default {
	data: function () {
		return {
			showPromo: false
		};
	},
	name: 'Promo',
	created() {
		if (_TARGET_ === 'client' && !Number(get('hidePromotionPackage2019'))) {
			this.showPromo = true;
		}

	},
	methods: {
		closePromo() {
			set('hidePromotionPackage2019', 1, { expires: PERIOD });
			this.showPromo = false;
		}
	},
	computed: {
		...mapGetters({
			advArr: 'cabinet/advertisements/advertisementsActive'
		}),
		hrefLogic() {
			switch (this.advArr.length) {
				case 0:
					return '/add_auto.html';
					break;
				case 1:
					return `/search/?levelsPopup=${this.advArr[0]}`;
					break;
				default:
					return '/fishki-group';
					break;
			}
		}
	},
	i18n: {
		messages: {
			uk: {
				'Готові до росту продажів?': 'Готові до росту продажів?',
				'Акція «Високий сезон»': 'Акція «Високий сезон»',
				'з 23 вересня до 23 жовтня купуйте рівні ТОП на термін від трьох днів та отримуйте бонуси для вигідних та швидких продажів!': 'з 23 вересня до 23 жовтня купуйте рівні ТОП на термін від трьох днів та отримуйте бонуси для вигідних та швидких продажів!',
				Від: 'Від',
				дні: 'дні',
				'і більше': 'і більше',
				'Деталі акції — у Вашого персонального менеджера': 'Деталі акції — у Вашого персонального менеджера',
				'Выбрать ТОП': 'Обрати ТОП'
			},
			ru: {
				'Готові до росту продажів?': 'Готовы к росту продаж?',
				'Акція «Високий сезон»': 'Акция «Высокий сезон»',
				'з 23 вересня до 23 жовтня купуйте рівні ТОП на термін від трьох днів та отримуйте бонуси для вигідних та швидких продажів!': 'с 23 сентября по 23 октября покупайте уровни ТОП на период от трех дней и получайте бонусы для выгодных и быстрых продаж!',
				Від: 'От',
				дні: 'дня',
				'і більше': 'и более',
				'Деталі акції — у Вашого персонального менеджера': 'Детали акции — у Вашего персонального менеджера',
				'Выбрать ТОП': 'Выбрать ТОП'
			}
		}
	}
};
