export default {
	messages: {
		ru: {
			'Заявки покупателей': 'Заявки покупателей',
			'Тест-драйвы': 'Тест-драйвы',
			'Заявки на авто': 'Заявки на авто',
			'Возможности личного кабинета': 'Возможности личного кабинета',
			'Просмотрите рекомендации, которые улучшают продажи': 'Просмотрите рекомендации, которые улучшают продажи',
			'Посмотреть рекомендации': 'Посмотреть рекомендации',
		},
		uk: {
			'Заявки покупателей': 'Заявки покупців',
			'Тест-драйвы': 'Тест-драйви',
			'Заявки на авто': 'Заявки на авто',
			'Возможности личного кабинета': 'Можливості особистого кабінету',
			'Просмотрите рекомендации, которые улучшают продажи': 'Перегляньте рекомендації, які покращують продажі',
			'Посмотреть рекомендации': 'Переглянути рекомендації',
		}
	}
};
