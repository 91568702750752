import getDeviceOs from '../../../../../helpers/getDeviceOs';

export default {
  data() {
    return {
      showIos: false,
      showAndroid: false,
      showHuawei: false,
      showAll: true,
    };
  },
  name: 'NewTeaser',
  computed: {
    androidLink() {
      return `https://play.google.com/store/apps/details?id=com.ria.auto&hl=uk`;
    },
    appStoreLink() {
      return `https://apps.apple.com/ua/app/auto-ria-cars-for-sale/id1083363297?l=uk?l=uk`;
    },
    appGalleryLink() {
      return 'https://appgallery.huawei.com/app/C101464449';
    },
  },
  mounted() {
    const deviceOs = getDeviceOs();
    switch (deviceOs) {
      case 'huawei':
        this.showHuawei = true;
        this.showAll = false;
        break;
      case 'android':
        this.showAndroid = true;
        this.showAll = false;
        break;
      case 'ios':
        this.showIos = true;
        this.showAll = false;
        break;
    }
  },
};
