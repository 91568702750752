import {mapGetters} from 'vuex';

export default {
	data: function () {
		return {
		};
	},
	name: 'UnpaidOrders',
	i18n: require('./i18n').default,
	init({ store, context }) {
		let {'cabinet/blocks/blocks': blocks} = store.getters;
		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}
		return blocks[this.name] ? store.dispatch('cabinet/billing/unpaidOrders', cookies) : '';
	},
	computed: {
		...mapGetters({
			unpaidOrders: 'cabinet/billing/unpaidOrders',
			langId: 'lang/id'
		})
	}
};
