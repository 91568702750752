import { mapGetters } from 'vuex';

export default {
	i18n: require('./i18n').default,
	data: function () {
		return {
			company: {
				dateClose: undefined,
				daysToClose: undefined
			},
			package: {
				dateClose: undefined,
				daysToClose: undefined
			}
		};
	},
	computed: {
		...mapGetters({
			commercialServices: 'cabinet/content/commercialServices',
			segment: 'cabinet/segment'
		})
	}
};
