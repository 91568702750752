import { mapGetters, mapActions } from 'vuex';

export default {
  i18n: require('./i18n').default,
  data() {
    return {
      counters: {},
    };
  },
  name: 'Statistics',
  components: {
    'expired-auto-dealers': require('../../components/ExpiredAutoDealers/index.vue').default,
  },
  computed: {
    ...mapGetters({
      statistic: 'cabinet/content/statistic',
      statisticCounters: 'cabinet/statisticCounters/statisticCounters',
      langId: 'lang/id',
    }),
  },
  created() {
    if (_TARGET_ === 'client') {
      this.getCounters();
    }
    this.counters = this.statisticCounters;
  },
  methods: {
    ...mapActions({
      getCounters: 'cabinet/statisticCounters/statisticCounters',
    }),
  },
};
