import {mapGetters, mapActions} from "vuex";
import moment from "moment";
const {set, get} = require('../../../../helpers/cookie');
const PERIOD = 60 * 60 * 24 * 30;

export default {

	i18n: require('./i18n').default,
	name: 'TopShare',
	data(){
		return {
			startTop: moment("2020-10-15 00:00:00"),
			endTop: moment("2020-11-16 00:00:00"),
			dateNow: moment(),
			secondsDurationToEnd: 0,
			dateText: '',
			isVIN: true,
			showTopSale: false,
			isShowAlert: true
		}
	},
	mounted() {
		this.checkSaleDate();
		this.fetchVinDataByUserId()
			.then((data) => {
				if (data && data.no[0].count !== 0) {
					this.isVIN = false;
				}
			});

		if (this.segment === 'b2cAutoSalon') {
			this.showTopSale = false;
		}
	},
	watch: {
		segment: function () {
			if (this.segment !== undefined && typeof this.segment === 'string') {
				if (this.segment === 'b2cAutoSalon') {
					this.showTopSale = false;
				}
			}
		}
	},
	methods: {
		...mapActions({
			fetchVinDataByUserId: 'cabinet/advertisements/fetchVinDataByUserId'
		}),
		closeTopAction() {
			set('topSale_mainCabinet', 1, { expires: PERIOD });
			this.showTopSale = false;
		},
		showAlert() {
			return Boolean(_TARGET_ === 'client' && !Number(get('topSale_mainCabinet')));
		},
		checkSaleDate() {
            this.showTopSale = Boolean(this.dateNow.isBetween(this.startTop, this.endTop) && this.showAlert());
		},
	},
	computed: {
		...mapGetters({
			getVinDataByUserId: 'cabinet/advertisements/getVinDataByUserId',
			segment: 'cabinet/segment',
		}),
		currentDateToEnd: {
			get: function () {
				return this.timeToEnd;
			},
			set: function (data) {
				return data;
			}
		}
	},
};
