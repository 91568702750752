export default {
	messages: {
		ru: {
			'Экономьте до': 'Экономьте до',
			'на продвижении объявлений в ТОП': 'на продвижении объявлений в ТОП',
			'Подробнее о продвижении': 'Подробнее о продвижении'
		},
		uk: {
			'Экономьте до': 'Економте до',
			'на продвижении объявлений в ТОП': 'на просуванні оголошень в ТОП',
			'Подробнее о продвижении': 'Детальніше про просування'
		}
	}
};
