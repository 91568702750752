import {mapActions, mapGetters} from 'vuex';

export default {
	i18n: require('./i18n').default,
	data: function () {
		return {};
	},
	name: 'Notepad',
	mounted() {
		if (!Object.keys(this.notepad).length) {
			this.setNotepad();
		}
	},
	methods: {
		...mapActions({
			setNotepad: 'cabinet/notepad/notepad',
		}),
	},
	computed: {
		...mapGetters({
			blocks: 'cabinet/blocks/blocks',
			notepad: 'cabinet/notepad/notepad'
		})
	}
};
