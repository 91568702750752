export default {
	messages: {
		ru: {
			'Управление звонками покупателей': 'Управление звонками покупателей',
			'звонков получено': 'звонков получено',
			'На вашем счету осталось': 'На вашем счету осталось',
			'Пополнить счет': 'Пополнить счет',
			'Марка': 'Марка',
			'Ставка': 'Ставка',
			'Получено': 'Получено',
			'Повысить ставку': 'Повысить ставку',
			'Смотреть ещё': 'Смотреть ещё',
			'марки': 'марки'
		},
		uk: {
			'Управление звонками покупателей': 'Управління дзвінками покупців',
			'звонков получено': 'дзвінків отримано',
			'На вашем счету осталось': 'На вашому рахунку залишилося',
			'Пополнить счет': 'Поповнити рахунок',
			'Марка': 'Марка',
			'Ставка': 'Ставка',
			'Получено': 'Oтримано',
			'Повысить ставку': 'Підвищити ставку',
			'Смотреть ещё': 'Дивитися ще',
			'марки': 'марки'
		}
	}
};
