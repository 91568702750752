import Timer from '../../../../FinalPage/common/Timer/index.vue';
import CabinetSlidesComponent from '../CabinetSlidesComponent/index.vue';
export default {
  props: {
    endDate: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      date: new Date(this.endDate),
    };
  },
  name: 'CabinetSlidesBlockId139',
  components: {
    Timer,
    CabinetSlidesComponent
  },
  computed: {
    endDay() {
      if(!this.endDate) return null
      return this.m(this.date).format('DD.MM');
    },
  },
};
