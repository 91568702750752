import {mapActions, mapGetters} from 'vuex';
import Advertisement from './../../Advertisements/Advertisement/index.vue';
import MoveToArchivePopup from './../../Advertisements/MoveToArchivePopup/index.vue';
import RepublicationPopup from './../../Advertisements/RepublicationPopup/index.vue';
import SwitchPhone from './../../Advertisements/SwitchPhone/index.vue';
import DeletePopup from './../../Advertisements/DeletePopup/index.vue';
import QRCode from './../../Advertisements/QRCode/index.vue';

import _svg from './../../Advertisements/svg.vue';
import scrollToTop from '../../../../helpers/cabinetScroll';

export default {
	mounted() {
		if (this._result(this._auth, 'userId') && !Object.keys(this.advArr).length) {
			this.pullComponent();
		}
	},
	name: 'Advertisements',
	components: {
		Advertisement: Advertisement,
		'adv-svg': _svg,
		MoveToArchivePopup,
		RepublicationPopup,
		SwitchPhone,
		DeletePopup,
        QRCode
	},
	i18n: {
		messages: {
			uk: {
				Опубликованные: 'Опубліковані',
				'опубликованных объявлений': 'опублікованих оголошень | опублікованe оголошення | опублікованих оголошення | опублікованих оголошень',
				'Управление объявлениями': 'Керування оголошеннями'
			},
			ru: {
				Опубликованные: 'Опубликованные',
				'опубликованных объявлений': 'опубликованных объявлений | опубликованное объявление | опубликованных объявления | опубликованных объявлений',
				'Управление объявлениями': 'Управление объявлениями'
			}
		}
	},
	computed: {
		...mapGetters({
			blocks: 'cabinet/blocks/blocks',
			_auth: 'cabinet/userInfo/_auth',
			advArr: 'cabinet/advertisements/advertisementsActive',
			info_: 'cabinet/advertisements/advertisement/info',
			showLoader: 'cabinet/advertisements/showLoader',
			popup: 'cabinet/advertisements/popups/popup'
		})
	},
	watch: {
		_auth() {
			this.pullComponent();
		}
	},
	methods: {
		scrollToTop,
		...mapActions({
			setAdvertisements: 'cabinet/advertisements/fetch',
			fetchAdvertisements: 'cabinet/advertisements/advertisement/fetch'
		}),
		pullComponent() {
			this.setAdvertisements().then(() => {
				this.fetchAdvertisements({ids: this.advArr.slice(0, 2)});
			});
		}
	}
};
