import { mapActions, mapGetters } from 'vuex';
import i18n from './i18n';

export default {
  i18n,
  data() {
    return {
      showMessage: false,
    };
  },
  computed: {
    ...mapGetters({
      publicationDiscounts: 'cabinet/content/publicationDiscounts',
      userBudget: 'cabinet/content/userBudget',
    }),
    refillAmount() {
      return this.userBudget.refillAmount;
    },
  },
  methods: {
    ...mapActions({
      createRefillOrder: 'others/createRefillOrder',
    }),
    close() {
      this.showMessage = false;
    },
    createOrder() {
      this.showMessage = true;
      const options = { amount: this.refillAmount };
      if (this.isDesktop) {
        const billingWindow = window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank', 'fullscreen=no,height=768,width=1024,toolbar=no,scrollbars=yes');
        this.createRefillOrder(options)
          .then((response) => {
            billingWindow.location.href = `https://oplaty.ria.com/auto/order/${this._result(response, 'orderId')}`;
          }).catch((error) => {
            console.error(`Refill order error -->${JSON.stringify(error)}`);
            billingWindow.close();
          });
      } else {
        this.createRefillOrder(options)
          .then((response) => {
            window.location = `https://oplaty.ria.com/auto/order/${this._result(response, 'orderId')}`;
          }).catch((error) => {
            console.error(`Refill order error -->${JSON.stringify(error)}`);
          });
      }
    },
  },
};
