import getDeviceOs from '../../../../../../helpers/getDeviceOs';

export default {
  data() {
    return {
      showIos: false,
      showAndroid: false,
      showHuawei: false,
      showAll: true,
    };
  },
  name: 'Store',
  computed: {
    androidLink() {
      return `https://play.google.com/store/apps/details?id=com.ria.auto${this.langCode === 'ru' ? '' : '&hl=uk'}`;
    },
    appStoreLink() {
      return `${this.langCode === 'ru' ? 'https://apps.apple.com/app/id1083363297' : 'https://apps.apple.com/ua/app/auto-ria-новые-и-б-у-авто/id1083363297?l=uk'}`;
    },
    appGalleryLink() {
      return 'https://appgallery.huawei.com/#/app/C101464449?sharePrepath=ag&channelId=web%20promo%20page%20auto.ria&referrer=web%20promo%20page%20auto.ria&id=f38e9c2560d14c838d3ace0bc8181cde&s=B74C645CC50E67106596D2317A69B95E41D5314063944F021532BE6F6EBE0BF5&detailType=0&v=';
    },
  },
  mounted() {
    const deviceOs = getDeviceOs();
    switch (deviceOs) {
      case 'ios':
        this.showIos = true;
        this.showAll = false;
        break;
      case 'android':
        this.showAndroid = true;
        this.showAll = false;
        break;
      case 'huawei':
        this.showHuawei = true;
        this.showAll = false;
        break;
    }
  },
};
