import {mapGetters} from 'vuex';

export default {
	name: 'OrdersCustomers',
	i18n: require('./i18n').default,
	data: function () {
		return {
		};
	},
	computed: {
		...mapGetters({
			ordersCustomers: 'cabinet/content/ordersCustomers'
		})
	}
};
