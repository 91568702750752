import AdvertisementPhoto from '../../../Advertisements/Advertisement/Photo/index.vue';
import FileLoaderWithTimer from '../FileLoaderWithTimer/index.vue';
import i18n from '../i18n';

export default {
  name: 'ModerationItem',
  props: {
    item: Object,
  },
  components: {
    AdvertisementPhoto,
    FileLoaderWithTimer,
  },
  computed: {
    usdPrice() {
      return this.item.price?.all?.USD?.value || 0;
    },
    uahPrice() {
      return this.item.price?.all?.UAH?.value || 0;
    },
    imgUrl() {
      if (this.item.photos && this.item.photos.main && this.item.photos.main.id) {
        return `https://cdn3.riastatic.com/photosnew/auto/photo/${this.item.brand.eng}_${this.item.model.eng}__${this.item.photos.main.id}bx`;
      }
      return 'https://img6.auto.ria.com/images/nophoto/no-photo-295x195';
    },
  },
  methods: {
    convertToString(value) {
      return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
  i18n,
};
