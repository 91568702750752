var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:{
                    'my-statistic' : _vm.statistic.data && Object.keys(_vm.statistic.data).length,
                    'segment'      : !_vm.statistic.data || !Object.keys(_vm.statistic.data).length
                 }},[_c('no-ssr',[(Object.keys(_vm.statistic.data).length)?_c('expired-auto-dealers',{attrs:{"type":'statistic'}}):_vm._e()],1),_c('span',{class:{hide: !_vm.statistic.data || !Object.keys(_vm.statistic.data).length}},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"bold size18"},[_vm._v("\n                "+_vm._s(_vm.$t('Статистика ваших объявлений за'))+"\n                "+_vm._s(_vm.m().format("DD") === '01'
                            ? _vm.m().subtract(1, 'months').format("MMMM")
                            : _vm.m().format("MMMM"))+"\n            ")]),(_vm.statistic.link)?_c('a',{staticClass:"d-area size13",attrs:{"href":`${_vm.langPrefix}${_vm.statistic.link}`}},[_vm._v("\n                "+_vm._s(_vm.$t('Продать быстрее'))+" →\n            ")]):_vm._e()]),_c('div',{staticClass:"grid-statistic"},_vm._l((_vm.statistic.blocks),function(item){return _c('div',{class:[{
                             item: true,
                             hide: !_vm._result(_vm.statistic, `data.${item}.count`),
                          }, _vm.statistic.data[item].class]},[_c('span',{staticClass:"count"},[_vm._v("\n                    "+_vm._s(_vm.statistic.data[item] && _vm.statistic.data[item].count || 0)+"\n                ")]),_c('span',{staticClass:"label-hot",class:{hide: !Boolean(_vm.statistic.data[item] && _vm.statistic.data[item].day)},attrs:{"title":_vm.$t('за сегодня')}},[_vm._v("\n                    +"+_vm._s(_vm._f("numberFormat")(_vm.statistic.data[item] && _vm.statistic.data[item].day))+"\n                ")]),_c('div',{staticClass:"grey size13"},[_vm._v("\n                    "+_vm._s(_vm.$tc(`${item}`, _vm.statistic.data[item] && _vm.statistic.data[item].count || 0))+"\n                    "),(_vm.statistic.data[item].link)?[_c('br',{staticClass:"dhide"}),_c('a',{attrs:{"href":`${_vm.langPrefix}${_vm.statistic.data[item].link.href}`}},[_vm._v("\n                            "+_vm._s(_vm.statistic.data[item].link[_vm.langId===2 ? 'ru': 'uk'])+"\n                        ")])]:_vm._e()],2)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }