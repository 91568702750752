import Timer from '../../../../FinalPage/common/Timer/index.vue';

export default {
  props: {
    endDate: {
      type: String,
      required: true,
      default: "",
    },
  },
  name: 'IndependenceDay',
  data() {
    return {
      date: new Date(this.endDate),
    };
  },
  components: {
    Timer,
  },
  computed: {
    amountBenefit() {
      if (new Date().getDate() === 23) {
        return `-50%`;
      }
      if (new Date().getDate() === 24) {
        return `-30%`;
      }
      if (new Date().getDate() === 25) {
        return `-25%`;
      }
    },
  },
};
