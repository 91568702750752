export default {
	messages: {
		ru: {
			'Статистика ваших объявлений за': 'Статистика ваших объявлений за',
			'Продать быстрее': 'Продать быстрее',
			'за сегодня': 'за сегодня',
			'advertisementViews': 'просмотров предложений | просмотр предложений | просмотра предложений | просмотров предложений',
			'phoneViews': 'просмотров контактов | просмотр контактов | просмотра контактов | просмотров контактов',
			'bookmarked': 'в избранном',
			'wallMessages': 'комментариев | комментарий | комментария | комментариев',
			'wallAuctions': 'предложений торга | предложение торга | предложения торга | предложений торга',
			'wallExchanges': 'предложений обмена | предложение обмена | предложения обмена | предложений обмена',
			'newCarsActive': 'ваших новых авто | вашe новое авто | ваших новых авто | ваших новых авто',
			'buCarsActive': 'ваших б/у авто | вашe б/у авто | ваших б/у авто | ваших б/у авто',
			'yourAdvertisementsActive': 'ваших предложений | ваше предложение | ваших предложения | ваших предложений',
			'disassemblyPhoneViews': 'просмотров телефонов | просмотр телефонов | просмотра телефонов | просмотров телефонов',
			'disassemblyViews': 'просмотров авторазборки | просмотр авторазборки | просмотра авторазборки | просмотров авторазборки',
			'calls': 'звонков | звонок | звонка | звонков',
			'orders': 'заявок | заявка | заявки | заявок',
			'allPagesViews': 'просмотров всех страниц | просмотр всех страниц | просмотра всех страниц | просмотров всех страниц',
			'callsWithLink': 'звонков | звонок | звонка | звонков',
			'partsOrders': 'заявок на запчасти | заявка на запчасти | заявки на запчасти | заявок на запчасти',
			'phoneViewsSalon': 'просмотров контактов | просмотр контактов | просмотра контактов | просмотров контактов',
			'Статистика ваших предложений за месяц' : 'Статистика ваших предложений за месяц',
			'Продлить пакет': 'Продлить пакет',
			'advertisementsSell': 'продано авто',
			'universalCallsChat': 'уникальных обращений в чате',
			'chatExchangeOffers': 'предложений обмена | предложение обмена | предложения обмена | предложений обмена',
			'chatBiddingOffers': 'предложений торга | предложение торга | предложения торга | предложений торга'
		},
		uk: {
			'Статистика ваших объявлений за': 'Статистика ваших оголошень за',
			'Продать быстрее': 'Продати швидше',
			'за сегодня': 'за сьогодні',
			'advertisementViews': 'переглядів пропозицій | перегляд пропозицій | перегляди пропозицій | переглядів пропозицій',
			'phoneViews': 'переглядів контактів | перегляд контактів | перегляди контактів | переглядів контактів',
			'bookmarked': 'в обраному',
			'wallMessages': 'коментарів | коментар | коментаря | коментарів',
			'wallAuctions': 'пропозицій торгу | пропозиція торгу | пропозиції торгу | пропозицій торгу',
			'wallExchanges': 'пропозицій обміну | пропозиція обміну | пропозиції обміну | пропозицій обміну',
			'newCarsActive': 'ваших нових авто | вашe новe авто | ваших нових авто | ваших нових авто',
			'buCarsActive': 'ваших вживаних авто | вашe вживаних авто | ваших вживаних авто | ваших вживаних авто',
			'yourAdvertisementsActive': 'ваших пропозицій | ваша пропозиція | ваших пропозиції | ваших пропозицій',
			'disassemblyPhoneViews': 'переглядів телефонів | перегляд телефонів | перегляди телефонів | переглядів телефонів',
			'disassemblyViews': 'переглядів авторозбірки | перегляд авторозбірки | перегляди авторозбірки | переглядів авторозбірки',
			'calls': 'дзвінків | дзвінок | дзвінка | дзвінків',
			'orders': 'заявок | заявка | заявки | заявок',
			'allPagesViews': 'переглядів всіх сторінок | перегляд всіх сторінок | перегляди всіх сторінок | переглядів всіх сторінок',
			'callsWithLink': 'дзвінків | дзвінок | дзвінка | дзвінків',
			'partsOrders': 'заявок на запчастини | заявка на запчастини | заявки на запчастини | заявок на запчастини',
			'phoneViewsSalon': 'переглядів контактів | перегляд контактів | перегляди контактів | переглядів контактів',
			'Статистика ваших предложений за месяц': 'Статистика ваших авто за місяць',
			'Продлить пакет': 'Продовжити пакет',
			'advertisementsSell': 'продано авто',
			'universalCallsChat': 'унікальних звернень в чаті',
			'chatExchangeOffers': 'пропозицій обміну | пропозиція обміну | пропозиції обміну | пропозицій обміну',
			'chatBiddingOffers': 'пропозицій торгу | пропозиція торгу | пропозиції торгу | пропозицій торгу'
		}
	}
};
