import { mapMutations } from 'vuex';

const multipliers = [
	{ key: 'day', value: 86400 },
	{ key: 'hour', value: 3600 },
	{ key: 'minute', value: 60 },
	{ key: 'second', value: 1 },
];

export default {
	name: 'Timer',
	props: ['initDate', 'isColored', 'startPoint'],
	data() {
		return {
			first: 0,
			second: 0,
			third: 0,
			intervalId: null,
		};
	},
	computed: {
		withPlaceholder() {
			return !this.first && !this.second && !this.third && this.startPoint_ !== 'day' ;
		},
		startDate() {
			return new Date(this.initDate).getTime() / 1000;
		},
		firstArr() {
		    return this.numToShow(this.first, this.currentMultipliers[0].key);
		},
		secondArr() {
		    return this.numToShow(this.second, this.currentMultipliers[1].key);
		},
		thirdArr() {
		    return this.numToShow(this.third, this.currentMultipliers[2].key);
		},
		startPoint_() {
			return ['day', 'hour'].includes(this.startPoint) ? this.startPoint : 'day';
		},
		currentMultipliers() {
			let result = [];
			let toggle = false;
			for (let item of multipliers) {
				if (result.length === 3) {
					break;
				}
				if (item.key === this.startPoint_) {
					toggle = true;
				}
				if (toggle) {
					result.push(item);
				}
			}
			return result;
		},
		grey() {
			const { isColored, first, second, withPlaceholder } = this;
			return isColored && !first && !second && !withPlaceholder;
		},
		red() {
			const { isColored, first, second, withPlaceholder } = this;
			return isColored && !first && second < 10 && !withPlaceholder;
		},
		blue() {
			const { isColored, second, first, withPlaceholder } = this;
			return isColored && (second >= 10 || first) && !withPlaceholder;
		},
		classes() {
			const { isColored, first, second, withPlaceholder } = this;
			return {
				0: {
					grey: isColored && !first && !withPlaceholder
				},
				1: {
					grey: isColored && !first && !second && !withPlaceholder,
					red: isColored && !first && second && second < 10 && !withPlaceholder,
				},
				2: {
					grey: isColored && !first && !second && !withPlaceholder,
					red: isColored && !first && second < 10 && !withPlaceholder,
				}
			};
		}

	},
	methods: {
		...mapMutations({
			toggleTimer: 'auction/toggleTimer',
		}),
	    numToShow(num, key) {
			const { withPlaceholder } = this;
			let $num = String(num || 0);
			if ($num.length <= 1) {
				$num = `0${$num}`;
			}
			return [
				withPlaceholder ? '•' : $num[0],
				withPlaceholder ? '•' : $num[1],
				key
			];
		},
		calculateDate() {
			const now = Date.now() / 1000;
			let num = this.startDate - now;

			if (num <= 0) {
				clearInterval(this.intervalId);
				this.toggleTimer();
				return;
			}
			this.first = Math.floor(num / this.currentMultipliers[0].value);
			if (this.first >= 1) {
				num = num - (this.first * this.currentMultipliers[0].value);
			}
			if (!this.first && this.startPoint_ === 'day') {
				this.startPoint = 'hour';
			}
			this.second = Math.floor(num / this.currentMultipliers[1].value);
			if (this.second >= 1) {
				num = num - (this.second * this.currentMultipliers[1].value);
			}
			this.third = Math.floor(num / this.currentMultipliers[2].value);
		}
	},
	mounted() {
		this.calculateDate();
		this.intervalId = setInterval(() => {
		    this.calculateDate();
		}, 1000);
	},
	i18n: {
	    messages: {
	        ru: {
	            'day': 'дней',
	            'hour': 'часов',
	            'minute': 'минут',
				'second': 'секунд',
			},
			uk: {
	            'day': 'днів',
	            'hour': 'годин',
	            'minute': 'хвилин',
				'second': 'секунд',
			}
		}
	}
};
