import { mapActions, mapGetters } from 'vuex';
import ModerationItem from './ModerationItem/index.vue';
import i18n from './i18n';

export default {
  name: 'PostModeration',
  components: {
    ModerationItem,
  },
  data() {
    return {
      showMoreItems: false,
      countOfVisibleItems: 2,
    };
  },
  computed: {
    ...mapGetters({
      blocks: 'cabinet/blocks/blocks',
      _auth: 'cabinet/userInfo/_auth',
      advArr: 'cabinet/advertisements/advertisementsActive',
      moderatedInfoArr: 'cabinet/advertisements/advertisement/moderatedInfoArr',
      showLoader: 'cabinet/advertisements/showLoader',
    }),
    itemsWithoutDocs() {
      return this.moderatedInfoArr?.filter(({ postmoderation }) => postmoderation?.buttonShow);
    },
    computedItems() {
      return this.itemsWithoutDocs?.slice(0, this.showMoreItems
        ? this.itemsWithoutDocs.length
        : this.countOfVisibleItems);
    },
    loadedAllDocs() {
      return this.moderatedInfoArr.every(({ postmoderation }) => postmoderation?.color === 'green');
    },
    moreLessBtn() {
      return !this.showMoreItems
        ? this.$t('Ще авто', { count: this.itemsWithoutDocs.length - this.countOfVisibleItems })
        : this.$t('Згорнути');
    },
  },
  methods: {
    ...mapActions({
      fetchAdvertisements: 'cabinet/advertisements/advertisement/fetch',
    }),
    logAnalytic() {
      this._slonik({ event_id: 1455, action_block: 1, click_action: 2 });
    },
  },
  mounted() {
    if (this._result(this._auth, 'userId')) {
      this.fetchAdvertisements({ ids: this.advArr });
    }
  },
  i18n,
};
