import { mapGetters } from 'vuex';

export default {
  name: 'PromoFreePublication',
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
    }),
  },

  i18n: {
    messages: {
      uk: {
        'Безкоштовна публікація': 'Безкоштовна публікація',
        'Вкажіть VIN-код': 'Вкажіть VIN-код',
        і: 'і',
        'виставляйте на продаж': 'виставляйте на продаж',
        'безкоштовно публікуйте': 'безкоштовно публікуйте',
        Опублікувати: 'Опублікувати',
      },
      ru: {
        'Безкоштовна публікація': 'Бесплатная публикация',
        'Вкажіть VIN-код': 'Укажите VIN-код',
        і: 'и',
        'виставляйте на продаж': 'выставляйте на продажу',
        'безкоштовно публікуйте': 'бесплатно публикуйте',
        Опублікувати: 'Опубликовать',
      },
    },
  },
};
