export default {
	messages: {
		ru: {
			'Ваши последние поиски': 'Последние поиски'
		},
		uk: {
			'Ваши последние поиски': 'Останні пошуки'
		}
	}
};
