import {mapActions, mapGetters} from 'vuex';

export default {
	i18n: require('./i18n').default,
	data: function () {
		return {
		};
	},
	name: 'Subscriptions',
	mounted() {
		if (!Object.keys(this.subscriptions).length) {
			this.setSubscriptions();
		}
	},
	methods: {
		...mapActions({
			setSubscriptions: 'cabinet/subscriptions/subscriptions',
		}),
	},
	computed: {
		...mapGetters({
			subscriptions: 'cabinet/subscriptions/subscriptions'
		})
	}
};
