export default {
	messages: {
		ru: {
			'Інвестиції та рентабельність вашого бізнесу у': 'Инвестиции и рентабельность вашего бизнеса в',
			'months_1': 'январе',
			'months_2': 'феврале',
			'months_3': 'марте',
			'months_4': 'апреле',
			'months_5': 'майе',
			'months_6': 'июне',
			'months_7': 'июле',
			'months_8': 'августе',
			'months_9': 'сентябре',
			'months_10': 'октябре',
			'months_11': 'ноябре',
			'months_0': 'декабре',
		},
		uk: {
			'Інвестиції та рентабельність вашого бізнесу у': 'Інвестиції та рентабельність вашого бізнесу у',
			'months_1': 'січні',
			'months_2': 'лютому',
			'months_3': 'березні',
			'months_4': 'квітні',
			'months_5': 'травні',
			'months_6': 'червні',
			'months_7': 'липні',
			'months_8': 'серпні',
			'months_9': 'вересні',
			'months_10': 'жовтні',
			'months_11': 'листопаді',
			'months_0': 'грудні',
		}
	}
};
