import {mapGetters} from 'vuex';

const {set, get} = require('../../../../helpers/cookie');
const PERIOD = 60 * 60 * 24 * 30;

export default {
	data: function () {
		return {
			showInfoBar: false,
			rand2: Math.round(Math.random())
		};
	},
	name: 'Rotator',
	mounted() {
		if (_TARGET_ === 'client' && !Number(get('firstViewOnePublMessage'))) {
			this.showInfoBar = true;
		}
	},
	computed: {
		...mapGetters({
			segment: 'cabinet/segment'
		}),
	},
	methods: {
		removeInfoBar() {
			set('firstViewOnePublMessage', 1, { expires: PERIOD });
			this.showInfoBar = false;
		},
	},
	i18n: {
		messages: {
			uk: {
				'Бесплатная публикация!': 'Безкоштовна публікація!',
				'AUTO.RIA важно что бы каждый мог продать свой автомобиль': 'AUTO.RIA важливо, щоб кожен міг продати своє авто',
				'быстро и бесплатно': 'швидко та безкоштовно',
				'Теперь мы дарим': 'Тепер ми даруємо',
				'бесплатную': 'безкоштовну',
				'публикацию': 'публікацію',
				'каждый месяц': 'кожного місяця',
			},
			ru: {
				'Бесплатная публикация!': 'Бесплатная публикация!',
				'AUTO.RIA важно что бы каждый мог продать свой автомобиль': 'AUTO.RIA важно чтобы каждый мог продать свой автомобиль',
				'быстро и бесплатно': 'быстро и бесплатно',
				'Теперь мы дарим': 'Теперь мы дарим',
				'бесплатную': 'бесплатную',
				'публикацию': 'публикацию',
				'каждый месяц': 'каждый месяц',
			}
		}
	}
};
