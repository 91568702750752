import Timer from '../../../../FinalPage/common/Timer/index.vue';

export default {
  props: {
    endDate: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      date: new Date(this.endDate),
    };
  },
  name: 'NationalHolidaySellers50',
  components: {
    Timer,
  },
};
