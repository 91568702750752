export default {
  data    : function () {
    return {
    }
  },
  name: "AboutAuto",
  i18n: {
    messages: {
      uk: {
        'об автомобилях': 'про автомобілі',
        'Тест-драйвы': 'Тест-драйви',
        'Сравнения авто': 'Порівняння авто',
        'Отзывы об авто': 'Відгуки про авто',
        'Автомобильные новости': 'Автомобільні новини',
      },
      ru: {
        'об автомобилях': 'об автомобилях',
        'Тест-драйвы': 'Тест-драйвы',
        'Сравнения авто': 'Сравнения авто',
        'Отзывы об авто': 'Отзывы об авто',
        'Автомобильные новости': 'Автомобильные новости',
      }
    }
  }
}